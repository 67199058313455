export const getColorVariable = str => {
  const colors = {
    Burgundy: 'primary-1',
    'Rose white': 'secondary-3',
    White: 'white',
    Sand: 'primary-3',
  }

  return colors[str]
}
