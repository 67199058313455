import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ContentWrapper from './content-wrapper'
import { getColorVariable } from '../utils'

import 'swiper/swiper.scss'
import '../styles/swiper.scss'

SwiperCore.use([Navigation, Pagination])

const Container = styled.section`
  margin: 0 auto;
  border-radius: 2rem 2rem 0 0;
  padding: 4rem 0 6rem;

  ${({ background }) => `background: var(--color-${background});`}
  ${({ noPaddingTop }) => noPaddingTop && `padding-top: 0;`}

  @media (min-width: 62.5em) {
    padding: 6.4rem 0 8.4rem;

    ${({ noPaddingTop }) => noPaddingTop && `padding-top: 0;`}
    ${({ noPaddingBottom }) => noPaddingBottom && `padding-bottom: 2rem;`}
  }
`

const ContainerGallery = styled.div`
  height: 100%;
  max-width: 144rem;
  overflow: hidden;
  border-radius: 2rem;

  @media (min-width: 62.5em) {
    margin: 0 auto;
    transition: transform 300ms;

    ${({ $scale }) =>
      $scale &&
      `
      transform: scale(0.911);

      img {
        transform: scale(1.08);
        transition: transform 300ms !important;
      }

      &:hover {
        transform: scale(1);

        img {
          transform: scale(1);
        }
      }
    `}
  }
`

const CorneredImage = styled(Img)`
  border-radius: 2rem;
`

export const GalleryInner = ({ images, aspectRatio, scale }) => {
  const speed = typeof window !== 'undefined' && window.innerWidth > 768 ? 700 : 300
  return (
    <ContainerGallery $scale={scale}>
      <Swiper
        navigation
        pagination={{ clickable: true }}
        grabCursor={true}
        loop={true}
        speed={speed}
      >
        {images.map(({ fluid }, i) => (
          <SwiperSlide key={i}>
            <Img fluid={{ ...fluid, aspectRatio: aspectRatio ? aspectRatio : fluid.aspectRatio }} />
          </SwiperSlide>
        ))}
      </Swiper>
    </ContainerGallery>
  )
}

const Gallery = ({ images, backgroundColor, removeTopPadding, removeBottomPadding }) => {
  images.map(({ fluid }) => {
    fluid.sizes = '(min-width: 90em) 90em, 100vw'
  })

  return (
    <Container
      background={getColorVariable(backgroundColor || 'Rose white')}
      noPaddingTop={removeTopPadding}
      noPaddingBottom={removeBottomPadding}
    >
      {images.length > 1 ? (
        <GalleryInner images={images} aspectRatio={1440 / 734} scale={true} />
      ) : (
        <ContentWrapper>
          <CorneredImage fluid={{ ...images[0].fluid, aspectRatio: 16 / 9 }} />
        </ContentWrapper>
      )}
    </Container>
  )
}

GalleryInner.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  aspectRatio: PropTypes.number,
  scale: PropTypes.bool,
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
  removeTopPadding: PropTypes.bool,
  removeBottomPadding: PropTypes.bool,
}

export default Gallery
